.mainProjects {
  /* padding-top: 100px !important; */
  /* padding-bottom: 300px; */
  height: 100vh;
  background-image: url("../../img/homepb.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.mainProjects .mainTafta,
.mainProjects .mainVlae,
.mainProjects .mainDebarMaalo {
  text-align: center;
}

.mainProjects .taftaImg {
  width: 430px;
  height: 300px;
  margin-top: 15px;
  cursor: pointer;
}

.mainProjects .vlaeImg {
  width: 400px;
  height: 300px;
  margin-top: 15px;
  cursor: pointer;
}

.mainProjects a {
  text-decoration: none;
  color: #343a40;
  text-transform: uppercase;
}

/* .mainProjects .mainDebarMaalo span {
  color: rgba(255, 0, 0, 0.884);
  font-size: 50px;
  text-transform: uppercase;
  font-weight: bold;
  border: 5px inset rgba(255, 0, 0, 0.884);
  padding: 50px;
} */

.mainProjects .mainDebarMaalo .rotated {
  margin-top: 70px;
  transform: rotate(-10deg);
}

@media (max-width: 1024px) {
  .mainProjects {
    background-image: none;
    height: 100%;
    padding-bottom: 60px;
    margin-top: -15%;
  }

  .mainProjects .taftaImg {
    width: 345px;
    height: 250px;
    margin-bottom: 15px;
  }

  .mainProjects .vlaeImg {
    width: 340px;
    height: 250px;
    margin-bottom: 15px;
  }

  .mainProjects h3 {
    margin-top: 15px;
  }

  /* .mainProjects .mainDebarMaalo span {
    font-size: 45px;
    border: 5px inset rgba(255, 0, 0, 0.884);
    padding: 40px;
  } */
}
