/* New */

/* ///// */
.navWrap .container {
  margin-left: 0 !important;
  margin-right: 0 !important;
  max-width: 100%;
}

.navWrap {
  border-bottom: 5px solid #ff6e01;
}

.navWrap .logoImg {
  width: 160px;
}

.navWrap .myNavLink {
  width: 50%;
}

.myNav {
  padding: 0 60px;
}

.navItemi {
  margin-left: auto;
}

.myNav a {
  text-transform: uppercase;
  color: white !important;
  font-size: 21px;
  letter-spacing: 1px;
  text-decoration: none;
  margin-right: 19px;
  /* transition: 0.2s; */
}

.myNav a:hover {
  color: #ff6e01 !important;
}

.projects a {
  color: black !important;
}

@media (min-width: 992px) and (max-width: 1091px) {
  .myNav a {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .myNav {
    padding: 0;
  }

  .navWrap .logoImg {
    width: 100%;
  }

  .navContact {
    margin-left: 0.5rem !important;
  }

  .navbar-brand {
    margin-left: 0 !important;
  }
}

@media (max-width: 990px) {
  .myNav a {
    margin-bottom: 20px;
  }

  .navContact {
    margin-left: 0.5rem !important;
  }

  .navbar-brand {
    margin-left: 0 !important;
  }
}
