//Font Weight
$light: 300;
$regular: 400;
$medium: 500;
$semiBold: 600;
$bold: 700;
$extraBold: 800;

// Colors
$orange: #ff6e01;
$darkGray: #343a40;

// Custom classes
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.full-size-img {
  width: 100%;
  height: 100%;

  -o-object-position: center;
  object-position: center;
  &-contain {
    -o-object-fit: contain;
    object-fit: contain;
  }
  &-cover {
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.txt-center {
  text-align: center !important;
}

.txt-uppercase {
  text-transform: uppercase !important;
}

.mt-default {
  margin-top: 6rem;
}

.mb-default {
  margin-bottom: 6rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.heading {
  font-weight: $bold;
  font-size: 24rem;
  line-height: 1.2;
  &-black {
    color: #000;
  }
  @media (max-width: 500px) {
    font-size: 36rem;
  }
}
