.mainFooter {
  /* height: 100%; */
  background-color: #343a40;
  border-bottom: 5px solid #ff6e01;
  text-align: center;
  color: white;
  font-size: 13px;
  padding-bottom: 10px;
  /* position: absolute; */
  /* top: 0; */
  /* bottom: 10px; */
  /* margin-top: 20%; */
}

.mainFooter a {
  color: white;
  text-decoration: none;
}

.mainFooter i {
  font-size: 18px;
  margin-right: 25px;
}

.mainFooter a:hover,
.mainFooter i:hover {
  color: #ff6e01;
  cursor: pointer;
}

.mainFooter .firstRow {
  padding-top: 10px;
}

@media (max-width: 768px) {
  .mainFooter {
    padding: 10px;
  }
  .mainFooter .socialsRow {
    padding-top: 10px;
  }
}
