@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  font-family: "Roboto", sans-serif;
  position: relative;
}

.mainHomepage .firstRow {
  height: 100vh;
  background-image: url(/static/media/home1.47a246c4.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.mainHomepage .firstRow h1 {
  text-align: center;
  font-size: 60px;
}

.mainHomepage .firstRow h1 span {
  color: #ff6e01;
}

.mainHomepage .secondRow h1 {
  text-align: center;
  padding-bottom: 30px;
}

.mainHomepage .secondRow span {
  color: #ff6e01;
}

.mainHomepage .secondRow p {
  font-size: 20px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .mainHomepage .firstRow {
    background-attachment: unset;
  }
  .mainHomepage .aboutCompanyRow {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .mainHomepage .aboutCompany {
    padding-top: 0 !important;
    padding-bottom: 32px;
  }
}

.mainAboutUs .firstRow {
  height: 30vh;
  background-image: url(/static/media/home1.47a246c4.jpg);
  background-position-x: center;
  background-repeat: no-repeat;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 50px;
  width: 100%;
  background-size: cover;
}

.mainAboutUs .firstRow h1 {
  text-align: center;
  font-size: 60px;
}

.mainAboutUs .firstRow h1 span {
  color: #ff6e01;
}

.mainAboutUs .firstTab h2 {
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 30px;
}

.mainAboutUs .firstTab p {
  font-size: 16px;
  line-height: 30px;
  color: black;
}

.mainAboutUs .firstTab ul {
  padding: 0;
}

.mainAboutUs .firstTab ul li {
  list-style-type: none;
  font-size: 16px;
  color: #ff6e01;
  line-height: 30px;
}

.mainAboutUs .firstTab ul li i {
  font-size: 13px;
  color: #ff6e01;
}

.mainAboutUs .fTfirstCol {
  padding-bottom: 25px;
}

.mainAboutUs img {
  height: 450px !important;
}

.mainContact {
  height: 100vh;
}

.mainContact .firstRow {
  height: 30vh;
  background-image: url(/static/media/home1.47a246c4.jpg);
  background-position-x: center;
  background-repeat: no-repeat;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.mainContact .firstRow h1 {
  text-align: center;
  font-size: 60px;
  /* color: white; */
  /* font-weight: 600; */
}

.mainContact .firstRow h1 span {
  color: #ff6e01;
}

/* .mainContact .secondRow {
  padding: 125px;
} */

.mainContact .contactBorder {
  border: 5px solid #ff6e01;
  margin-top: 20px;
  margin-bottom: 20px;
}

.mainContact .sendMail {
  color: rgba(0, 0, 0, 0.856);
  text-decoration: underline;
}

.mainContact .card-body h5 {
  color: #ff6e01 !important;
}

.mainContact .workTime .card-body {
  line-height: 40px !important;
}

.mainContact .socialNetwork i {
  font-size: 30px;
  margin-right: 15px;
}

.mainContact .socialNetwork a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.856);
}

.mainContact .socialNetwork a:hover {
  color: #ff6e01;
}

@media (max-width: 768px) {
  .mainContact .contactBorder {
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .mainContact .contactBorder {
    margin-top: 50px;
    padding: 0;
    border: 0;
  }

  .mainContact .workTime {
    display: none;
  }
}

.firstProject .taftaTitle {
  text-align: center;
  color: #ff6e01;
}

.firstProject .taftaArcMain,
.firstProject .taftaOutMain,
.firstProject .taftaGradba,
.firstProject .taftaGradba2 {
  margin-left: 10%;
}

.firstProject .taftaArcMain img {
  width: 40%;
}

.firstProject .taftaOutMain img {
  width: 40%;
}

.firstProject .taftaGradba img {
  margin-bottom: 10px;
  width: 40%;
}

.firstProject .taftaGradba2 img {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 80%;
}

.firstProject .taftaInterior {
  margin-left: 10%;
}

.firstProject .taftaInterior img {
  width: 85%;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .firstProject {
    overflow-x: hidden;
    padding-right: 25px;
  }

  .firstProject .taftaArcMain img,
  .firstProject .taftaArc2,
  .firstProject .taftaOutMain img,
  .firstProject .taftaGradba img,
  .firstProject .taftaGradba2 img {
    width: 100%;
    height: unset !important;
    margin-bottom: 10px;
  }

  .firstProject .taftaInterior img {
    width: 100%;
    margin-bottom: 30px;
  }
}

/* Video */
.secondProject .video-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 20px;
}

.secondProject .video-wrap video {
  -webkit-flex: 0 0 49%;
          flex: 0 0 49%;
  max-width: 49%;
}

@media (max-width: 768px) {
  .secondProject .video-wrap {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .secondProject .video-wrap video {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .secondProject .video-wrap video:first-child {
    margin-bottom: 20px;
  }
}

.secondProject .vlaeTitle {
  text-align: center;
  color: #ff6e01;
}

.secondProject .vlaeArcMain,
.secondProject .vlaeOutMain {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.secondProject .carousel img {
  width: 750px;
  height: 720px;
}

.secondProject .renderCol img {
  margin-bottom: 20px;
}

.secondProject .lastImages img {
  width: 100%;
  height: 1000px;
}

.secondProject .introVlae img {
  width: 80%;
}
.secondProject .introVlae {
  border: 1px solid #ff6e01;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .secondProject .introVlae img {
    width: 100%;
    margin-top: 100px;
  }
  .secondProject .carousel img {
    width: 100%;
    height: 300px;
  }
  .secondProject .lastImages img {
    width: 100%;
    height: 100%;
  }
}

/* New */

/* ///// */
.navWrap .container {
  margin-left: 0 !important;
  margin-right: 0 !important;
  max-width: 100%;
}

.navWrap {
  border-bottom: 5px solid #ff6e01;
}

.navWrap .logoImg {
  width: 160px;
}

.navWrap .myNavLink {
  width: 50%;
}

.myNav {
  padding: 0 60px;
}

.navItemi {
  margin-left: auto;
}

.myNav a {
  text-transform: uppercase;
  color: white !important;
  font-size: 21px;
  letter-spacing: 1px;
  text-decoration: none;
  margin-right: 19px;
  /* transition: 0.2s; */
}

.myNav a:hover {
  color: #ff6e01 !important;
}

.projects a {
  color: black !important;
}

@media (min-width: 992px) and (max-width: 1091px) {
  .myNav a {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .myNav {
    padding: 0;
  }

  .navWrap .logoImg {
    width: 100%;
  }

  .navContact {
    margin-left: 0.5rem !important;
  }

  .navbar-brand {
    margin-left: 0 !important;
  }
}

@media (max-width: 990px) {
  .myNav a {
    margin-bottom: 20px;
  }

  .navContact {
    margin-left: 0.5rem !important;
  }

  .navbar-brand {
    margin-left: 0 !important;
  }
}

.mainFooter {
  /* height: 100%; */
  background-color: #343a40;
  border-bottom: 5px solid #ff6e01;
  text-align: center;
  color: white;
  font-size: 13px;
  padding-bottom: 10px;
  /* position: absolute; */
  /* top: 0; */
  /* bottom: 10px; */
  /* margin-top: 20%; */
}

.mainFooter a {
  color: white;
  text-decoration: none;
}

.mainFooter i {
  font-size: 18px;
  margin-right: 25px;
}

.mainFooter a:hover,
.mainFooter i:hover {
  color: #ff6e01;
  cursor: pointer;
}

.mainFooter .firstRow {
  padding-top: 10px;
}

@media (max-width: 768px) {
  .mainFooter {
    padding: 10px;
  }
  .mainFooter .socialsRow {
    padding-top: 10px;
  }
}

.mainProjects {
  /* padding-top: 100px !important; */
  /* padding-bottom: 300px; */
  height: 100vh;
  background-image: url(/static/media/homepb.49809e41.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.mainProjects .mainTafta,
.mainProjects .mainVlae,
.mainProjects .mainDebarMaalo {
  text-align: center;
}

.mainProjects .taftaImg {
  width: 430px;
  height: 300px;
  margin-top: 15px;
  cursor: pointer;
}

.mainProjects .vlaeImg {
  width: 400px;
  height: 300px;
  margin-top: 15px;
  cursor: pointer;
}

.mainProjects a {
  text-decoration: none;
  color: #343a40;
  text-transform: uppercase;
}

/* .mainProjects .mainDebarMaalo span {
  color: rgba(255, 0, 0, 0.884);
  font-size: 50px;
  text-transform: uppercase;
  font-weight: bold;
  border: 5px inset rgba(255, 0, 0, 0.884);
  padding: 50px;
} */

.mainProjects .mainDebarMaalo .rotated {
  margin-top: 70px;
  -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
}

@media (max-width: 1024px) {
  .mainProjects {
    background-image: none;
    height: 100%;
    padding-bottom: 60px;
    margin-top: -15%;
  }

  .mainProjects .taftaImg {
    width: 345px;
    height: 250px;
    margin-bottom: 15px;
  }

  .mainProjects .vlaeImg {
    width: 340px;
    height: 250px;
    margin-bottom: 15px;
  }

  .mainProjects h3 {
    margin-top: 15px;
  }

  /* .mainProjects .mainDebarMaalo span {
    font-size: 45px;
    border: 5px inset rgba(255, 0, 0, 0.884);
    padding: 40px;
  } */
}

a,b,blockquote,body,canvas,caption,code,div,em,embed,fieldset,figure,footer,form,h1,h2,h3,h4,h5,h6,header,html,i,iframe,img,label,li,main,nav,object,ol,p,pre,section,small,span,strike,strong,sub,sup,table,tbody,td,tfoot,th,thead,tr,tt,u,ul,video{text-rendering:optimizeLegibility;-webkit-font-smoothing:antialiased;-webkit-text-size-adjust:none;-moz-text-size-adjust:none;text-size-adjust:none;border:0;margin:0;padding:0;vertical-align:baseline;list-style-type:none}.mx-auto{margin-left:auto;margin-right:auto}.full-size-img{width:100%;height:100%;object-position:center}.full-size-img-contain{object-fit:contain}.full-size-img-cover{object-fit:cover}.txt-center{text-align:center !important}.txt-uppercase{text-transform:uppercase !important}.mt-default{margin-top:6rem}.mb-default{margin-bottom:6rem !important}.mb-0{margin-bottom:0 !important}.heading{font-weight:700;font-size:24rem;line-height:1.2}.heading-black{color:#000}@media(max-width: 500px){.heading{font-size:36rem}}.main .wrapper{background-color:#343a40;border-bottom:5px solid #ff6e01;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:space-between;justify-content:space-between;padding:0 120px}.main .wrapper .logo img{width:160px}.main .wrapper .menu-items ul{list-style:none;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:space-between;justify-content:space-between}.main .wrapper .menu-items ul a{color:#f3f3f3;text-transform:uppercase;font-size:19px;text-decoration:none}.main .wrapper .menu-items ul a:hover{color:#ff6e01}.main .wrapper .menu-items ul li:not(:last-child){margin-right:40px}.projects-row{margin-top:100px}.projects-row a img{border:2px solid #ff6e01;border-radius:3%}
/* Video */
.secondProject .video-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 20px;
}

.secondProject .video-wrap video {
  -webkit-flex: 0 0 49%;
          flex: 0 0 49%;
  max-width: 49%;
}

@media (max-width: 768px) {
  .secondProject .video-wrap {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .secondProject .video-wrap video {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .secondProject .video-wrap video:first-child {
    margin-bottom: 20px;
  }
}

.secondProject .vlaeTitle {
  text-align: center;
  color: #ff6e01;
}

.secondProject .vlaeArcMain,
.secondProject .vlaeOutMain {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.secondProject .carousel img {
  width: 750px;
  height: 720px;
}

.secondProject .renderCol img {
  margin-bottom: 20px;
}

.secondProject .lastImages img {
  width: 100%;
  height: 1000px;
}

.secondProject .introVlae img {
  width: 80%;
}
.secondProject .introVlae {
  border: 1px solid #ff6e01;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .secondProject .introVlae img {
    width: 100%;
    margin-top: 100px;
  }
  .secondProject .carousel img {
    width: 100%;
    height: 300px;
  }
  .secondProject .lastImages img {
    width: 100%;
    height: 100%;
  }
}

