/* resets */
a,
b,
blockquote,
body,
canvas,
caption,
code,
div,
em,
embed,
fieldset,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
i,
iframe,
img,
label,
li,
main,
nav,
object,
ol,
p,
pre,
section,
small,
span,
strike,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
u,
ul,
video {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  list-style-type: none;
}
