.firstProject .taftaTitle {
  text-align: center;
  color: #ff6e01;
}

.firstProject .taftaArcMain,
.firstProject .taftaOutMain,
.firstProject .taftaGradba,
.firstProject .taftaGradba2 {
  margin-left: 10%;
}

.firstProject .taftaArcMain img {
  width: 40%;
}

.firstProject .taftaOutMain img {
  width: 40%;
}

.firstProject .taftaGradba img {
  margin-bottom: 10px;
  width: 40%;
}

.firstProject .taftaGradba2 img {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 80%;
}

.firstProject .taftaInterior {
  margin-left: 10%;
}

.firstProject .taftaInterior img {
  width: 85%;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .firstProject {
    overflow-x: hidden;
    padding-right: 25px;
  }

  .firstProject .taftaArcMain img,
  .firstProject .taftaArc2,
  .firstProject .taftaOutMain img,
  .firstProject .taftaGradba img,
  .firstProject .taftaGradba2 img {
    width: 100%;
    height: unset !important;
    margin-bottom: 10px;
  }

  .firstProject .taftaInterior img {
    width: 100%;
    margin-bottom: 30px;
  }
}
