.mainContact {
  height: 100vh;
}

.mainContact .firstRow {
  height: 30vh;
  background-image: url("../../img/home1.jpg");
  background-position-x: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainContact .firstRow h1 {
  text-align: center;
  font-size: 60px;
  /* color: white; */
  /* font-weight: 600; */
}

.mainContact .firstRow h1 span {
  color: #ff6e01;
}

/* .mainContact .secondRow {
  padding: 125px;
} */

.mainContact .contactBorder {
  border: 5px solid #ff6e01;
  margin-top: 20px;
  margin-bottom: 20px;
}

.mainContact .sendMail {
  color: rgba(0, 0, 0, 0.856);
  text-decoration: underline;
}

.mainContact .card-body h5 {
  color: #ff6e01 !important;
}

.mainContact .workTime .card-body {
  line-height: 40px !important;
}

.mainContact .socialNetwork i {
  font-size: 30px;
  margin-right: 15px;
}

.mainContact .socialNetwork a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.856);
}

.mainContact .socialNetwork a:hover {
  color: #ff6e01;
}

@media (max-width: 768px) {
  .mainContact .contactBorder {
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .mainContact .contactBorder {
    margin-top: 50px;
    padding: 0;
    border: 0;
  }

  .mainContact .workTime {
    display: none;
  }
}
