//navbar
.main {
  .wrapper {
    background-color: $darkGray;
    border-bottom: 5px solid $orange;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 120px;

    .logo {
      img {
        width: 160px;
      }
    }

    .menu-items {
      ul {
        //   flex: 0 0 50%;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
          color: #f3f3f3;
          text-transform: uppercase;
          font-size: 19px;
          text-decoration: none;

          &:hover {
            color: $orange;
          }
        }

        li {
          &:not(:last-child) {
            margin-right: 40px;
          }
        }
      }
    }
  }
}
