/* Video */
.secondProject .video-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.secondProject .video-wrap video {
  flex: 0 0 49%;
  max-width: 49%;
}

@media (max-width: 768px) {
  .secondProject .video-wrap {
    flex-direction: column;
  }
  .secondProject .video-wrap video {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .secondProject .video-wrap video:first-child {
    margin-bottom: 20px;
  }
}

.secondProject .vlaeTitle {
  text-align: center;
  color: #ff6e01;
}

.secondProject .vlaeArcMain,
.secondProject .vlaeOutMain {
  display: flex;
  justify-content: center;
}

.secondProject .carousel img {
  width: 750px;
  height: 720px;
}

.secondProject .renderCol img {
  margin-bottom: 20px;
}

.secondProject .lastImages img {
  width: 100%;
  height: 1000px;
}

.secondProject .introVlae img {
  width: 80%;
}
.secondProject .introVlae {
  border: 1px solid #ff6e01;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .secondProject .introVlae img {
    width: 100%;
    margin-top: 100px;
  }
  .secondProject .carousel img {
    width: 100%;
    height: 300px;
  }
  .secondProject .lastImages img {
    width: 100%;
    height: 100%;
  }
}
