.mainAboutUs .firstRow {
  height: 30vh;
  background-image: url("../../img/home1.jpg");
  background-position-x: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  width: 100%;
  background-size: cover;
}

.mainAboutUs .firstRow h1 {
  text-align: center;
  font-size: 60px;
}

.mainAboutUs .firstRow h1 span {
  color: #ff6e01;
}

.mainAboutUs .firstTab h2 {
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 30px;
}

.mainAboutUs .firstTab p {
  font-size: 16px;
  line-height: 30px;
  color: black;
}

.mainAboutUs .firstTab ul {
  padding: 0;
}

.mainAboutUs .firstTab ul li {
  list-style-type: none;
  font-size: 16px;
  color: #ff6e01;
  line-height: 30px;
}

.mainAboutUs .firstTab ul li i {
  font-size: 13px;
  color: #ff6e01;
}

.mainAboutUs .fTfirstCol {
  padding-bottom: 25px;
}

.mainAboutUs img {
  height: 450px !important;
}
