.mainHomepage .firstRow {
  height: 100vh;
  background-image: url("../../img/home1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainHomepage .firstRow h1 {
  text-align: center;
  font-size: 60px;
}

.mainHomepage .firstRow h1 span {
  color: #ff6e01;
}

.mainHomepage .secondRow h1 {
  text-align: center;
  padding-bottom: 30px;
}

.mainHomepage .secondRow span {
  color: #ff6e01;
}

.mainHomepage .secondRow p {
  font-size: 20px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .mainHomepage .firstRow {
    background-attachment: unset;
  }
  .mainHomepage .aboutCompanyRow {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .mainHomepage .aboutCompany {
    padding-top: 0 !important;
    padding-bottom: 32px;
  }
}
